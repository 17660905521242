"use client";
import { builder, Builder } from "@builder.io/react";
import ScrollIndicatorArrows from "./components/ScrollIndicatorArrows";
import ScrollIndicatorMouseWheel from "./components/ScrollIndicatorMouseWheel";

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

Builder.registerComponent(ScrollIndicatorArrows, {
  name: "ScrollIndicatorArrows",
  inputs: [
    {
      name: "href",
      type: "string",
      required: true,
    },
  ],
});

Builder.registerComponent(ScrollIndicatorMouseWheel, {
  name: "ScrollIndicatorMouseWheel",
  inputs: [
    {
      name: "href",
      type: "string",
      required: true,
    },
  ],
});
