'use client';
import React from 'react';

type ScrollIndicatorMouseWheelProps = {
  href: string;
};

const ScrollIndicatorMouseWheel: React.FC<ScrollIndicatorMouseWheelProps> = ({
  href,
}) => {
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetElement = document.querySelector(href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="bg-[url('https://www.nxworld.net/example/css-scroll-down-button/bg10.jpg')] bg-center bg-cover bg-no-repeat flex items-center justify-center w-auto min-h-20 mb-5">
      <a
        onClick={handleScroll}
        className='absolute bottom-30 left-1/2 z-20 inline-block transform -translate-y-1/2 text-white font-[400] text-[20px] leading-[1] font-[Josefin Sans] tracking-[.1em] no-underline transition-opacity duration-300 hover:opacity-50 cursor-pointer'
      >
        <span className='absolute top-0 left-1/2 w-[30px] h-[50px] -ml-[15px] border-2 border-gray-300 rounded-full box-border'>
          <span className='absolute top-[10px] left-1/2 w-[6px] h-[6px] -ml-[3px] bg-gray-300 rounded-full animate-[sdmousewheel_2s_infinite]'></span>
        </span>
      </a>
    </section>
  );
};

export default ScrollIndicatorMouseWheel;
