'use client';
import React from 'react';

type ScrollIndicatorArrowsProps = {
  href: string;
};

const ScrollIndicatorArrows: React.FC<ScrollIndicatorArrowsProps> = ({
  href,
}) => {
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetElement = document.querySelector(href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className='relative w-auto h-auto min-h-18'>
      <a onClick={handleScroll} className='relative block cursor-pointer'>
        <span className='absolute top-0 left-1/2 w-4 h-4 -ml-3 border-l-2 border-b-2 -rotate-45 border-white transform opacity-0 animate-sdarrows'></span>
        <span className='absolute top-4 left-1/2 w-4 h-4 -ml-3 border-l-2 border-b-2 -rotate-45 border-white transform opacity-0 animate-sdarrows animation-delay-150'></span>
        <span className='absolute top-8 left-1/2 w-4 h-4 -ml-3 border-l-2 border-b-2 -rotate-45 border-white transform opacity-0 animate-sdarrows animation-delay-300'></span>
      </a>
    </section>
  );
};

export default ScrollIndicatorArrows;
