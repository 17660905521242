import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';

// tailwind class merge
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// dynamic icon loading based on name defined in headless CMS
export const loadIcon = async (iconName: string): Promise<IconType | null> => {
  try {
    // Dynamically import the specific icon module
    const iconModule = await import(`react-icons/md`); // Adjust the path for different icon sets if needed
    const IconComponent = iconModule[
      iconName as keyof typeof iconModule
    ] as IconType; // Cast to IconType
    return IconComponent || null; // Return the icon component or null if not found
  } catch (error) {
    console.error(`Icon ${iconName} could not be loaded:`, error);
    return null; // Return null if the icon couldn't be loaded
  }
};
